import styled, {css} from 'styled-components';

const RegularFont = 'Rubik-Regular';
const MediumFont = 'Rubik-Medium';
import { Modal } from 'react-bootstrap';
const TaskToCompleteSidebar = styled.div`
  width: 100%;
  background: ${({bgColor}) => bgColor ? '#FFFFFF' : 'unset'};
  border-radius: 6px;
  margin-bottom: ${({conneceFitnessDevice}) => conneceFitnessDevice ? '20px' : '0'};
`;

const Header = styled.div`
  width: 100%;
  padding: 15px 20px;
  padding-left: 0px;
  padding-top: 25px;
  padding-top: ${({paddingTop}) => paddingTop ? paddingTop : '25px'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px 6px 0px 0px;
  .text {
    width: 100%;
    display: inline-block;
    font-family: 'Rubik-Medium';
    font-weight: 500;
    font-size: 18px;
    color: #0D4270;
    line-height: 24px;
    > img {
      margin-right: 15px;
      width: 16px;
      vertical-align: middle;
    }
    > span {
      height: 100%;
      display: inline-block;
      color: #0D4270;
      font-size: 'Rubik-Medium';
    }
  }
`;

const TaskContainer = styled.div`
  position: relative;
  width: 100%;
  height: 85px;
  border-bottom: ${({borderNone}) => borderNone ? 'none' : '2.5px solid #F6F6F8'};
  box-shadow: ${({borderNone}) => borderNone ? '0px 5px 10px rgba(0, 0, 0, 0.08)' : 'none'};
  border-radius: ${({borderNone}) => borderNone ? '0 0 6px 6px' : 'unset'};
  background: #FFFFFF;
  cursor: pointer;
  > img {
    float: left;
    padding: 5px;
    width: 73px;
    height: 71px;
    margin: 7px;
  }
`;

const PointeContainer = styled.div`
  padding: 4px;
  margin-top: 25px;
  border: 0.84px solid #E9E9E9;
  box-sizing: border-box;
  border-radius: 2.56px;
  display: inline-block;
  width: 60%;
  font-family: ${MediumFont};
  font-size: 11px;
  text-align: center;
  color: #1E76AB;
`;

const Content = styled.div`
  width: ${({connectDevice}) => connectDevice ? 'calc(100% - 45px)' : 'calc(100% - 88px)'};
  height: 100%;
  display: table;

  .text {
    color: rgba(38, 38, 43, 0.7);
    font-size: 12px;
    width: 50%;
    height: 100%;
    word-break: break-word;
    vertical-align: middle;
    display: table-cell;

    @media (max-width: 530px) {
      margin-top: 30px;
    }
  }

  >span {
    position: relative;
    
    > svg {
      display: table-cell;
      float: right;
      margin-right: 10px;
      cursor: pointer;
      width: 25%;
      vertical-align: middle;
    }
  }

  > svg {
    display: table-cell;
    float: right;
    margin-right: 10px;
    cursor: pointer;
    width: 25%;
    vertical-align: middle;
  }
`;

const ConnectFitnessDeviceContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 85px;
  margin-top: 5px;

  > img {
    width: 25px;
    float: left;
    margin: 0 32px;
  }
`;

const ConnectButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  float: right;
  height: 40px;
  width: 120px;;
  background: #4798D9;
  border-radius: 3.2px;
  cursor: pointer;
  padding: 11px;
  margin: 0 40px;
  @media (max-width: 1023px) {
    margin: 0 20px;
  }
  > span {
    font-family: Avenir;
    font-size: 13.8px;
    line-height: 16px;
    color: #FFFFFF;
    float: left;
  }
`;

const RecommendedAmigosSection = styled.div`
  margin-top: 20px;

  > span {
    font-family: ${RegularFont};
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #26262B;
    mix-blend-mode: normal;
    opacity: 0.7;
  }
`;

const RecommendedAmigosList = styled.div`
  float: left;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid rgb(222, 222, 223);
  padding: ${({empty}) => empty ? '20px' : '15px 10px 15px 5px' };
  background: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({marginTop}) => marginTop && '15px'};
  > img {
    height: 48px;
    width: 48px;
    border-radius: 100px;
    margin: 3px;
  }

  > span {
    font-family: ${RegularFont};
    color: #282C37;
    text-transform: ${({empty}) => empty ? 'capitalize' : 'none'};
    font-size: 16px;
    margin-left: ${({empty}) => empty ? '0px' : '10px'};
    text-overflow: ellipsis;
    white-space: initial;
    overflow: hidden;
    @media (max-width: 765px) {
      padding-top: ${({empty}) => empty ? '35px' : '15px'};
    }
  }
  > svg {
    vertical-align: middle;
    float: right;
    margin-top: 8%;
    cursor: pointer;
  }
  .rightArrow {
    float: right;
    padding-top: 18px;
    cursor: pointer;
  }
`;
const RightContainer = styled.div`
  width: calc(100% - 89px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  .connectDevice {
    color: rgba(38,38,43,0.7);
    font-size: 12px;
    word-break: break-word;
    vertical-align: middle;
    display: inline-block;
  }
`;
const Arrow = styled.div`
width: 8%;
height: 100%;
float: left;
display: flex;
justify-content: center;
  > img {
    align-self: center;
  }

`;
const SubContainer = styled.div`
width: 100%;
height: 90px;
background-color: #FFF;
border-radius: 4px;
cursor: ${({cursor}) => cursor ? cursor : ''};
border-bottom: 1px solid #EAEAEA;
display: flex;
`;

const ImageContainer = styled.div`

margin: 15px;

div{
  > img {
    width: 60px;
    height: 60px;
  }

}

  > img {
    width: 60px;
    height: 60px;
  }
  @media(max-width: 766px) {
    width: 14%;
  }
  @media(max-width: 600px) {
    width: 20%;
  }
  @media(max-width: 430px) {
    width: 30%;
  }
`;
const DataContainer = styled.div`
width: 65%;
height: 100%;
float: left;
padding: 15px;
padding-left: 0px;
display: flex;
justify-content: space-evenly;
flex-flow: column;

  > div: first-child {
    font-size: 18px;
    font-family: 'Rubik-Medium';
    line-height: 21px;
    color: #0D4270;
    font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 19px;
color: #0D4270;
  }
  > div: last-child {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: ${({color}) => color ? color : '#0D4270'};
  }
  @media(max-width: 766px) {
    width: 76%;
  }
  @media(max-width: 600px) {
    width: 72%;
  }
  @media(max-width: 430px) {
    width: 62%;
  }
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    transform: ${({transform}) => transform ? 'translate(0, 15vh) translate(0, -15%) !important' : 'translate(0, 50vh) translate(0, -50%) !important'};
    margin: ${({change}) => change ? '150px auto' : '0 auto'};
    @media (max-height: 460px) {
      transform: none !important;
      margin: 30px auto;
    }
    width: ${({width}) => width ? width : '490px'};
    height: ${({change, height}) => change ? '573px' : height ? height : '473px'};
    @media (max-width: 500px){
      width: 95%;
      height: auto;
    }
  }

  .modal-body{
    padding: 25px 0 25px 0;
  }
  
  .modal-container {
   width: 100%;
   display: block;
   > div: nth-child(1) {
    width: 100%;
    height: 0px;
    display: flex;
    justify-content: flex-end;
    >img{
      width: 24px;
      height: 24px;
      cursor: pointer;
      margin: 15px 15px 0 0;
    }
   }
   > div: nth-child(2) {
    width: 100%;
    display:flex;
    justify-content: center;
    font-family: Rubik-Medium;
    font-size: 24px;
    line-height: 24px;
    color:#0D4270;
    text-decoration: underline;
    margin-top: 35px;
   }
  }
`;

const InitiativesContainer = styled.div`
  width: 100%;
  .initiative-icon {
   width: 140px;
   height: 140px;
   margin: auto;
   margin-bottom 25px;
    >img{
     width: 100%;
     height: 100%;
    }
  }
  .details{
    width: 100%;
    margin: auto;
    font-family: Rubik;
    font-size: 18px; // 16px;
    line-height: 24px; // 25.8px;
    color: #005C87; //#0D4270;
    text-align: center;
    padding: 0px 20px 0px 20px;
  }
  .details-initiative{
    font-family: 'Rubik-Bold';
    font-size: 18px; // 16px;
    line-height: 24px; // 25.8px;
    color: #005C87; // #0D4270;
  }
  .greatText{
    font-family: Rubik-Medium;
    font-size: 24px;
    line-height: 24px;
    color: ${({color}) => color ? color : '#0D4270'};
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .completionText{
    font-family: Rubik
    font-size: 16px;
    line-height: 16px;
    color:#0D4270;
    text-align: center;
    margin-bottom: 25px;
  }
  .initiativeText{
    font-family: Rubik-Medium;
    font-size: 18px;
    line-height: 20px;
    color:#0D4270;
    margin-bottom: 15px;
    text-align: center;
  }
  .congratulationText{
    font-family: Rubik-Medium;
    font-size: 18px;
    line-height: 20px;
    color: ${({color}) => color ? color : '#0D4270'};
    margin-bottom: 30px;
    text-align: center;
  }
`;

const ConfirmDetailsContainer = styled.div`
  width: 100%;
  padding: 0px 20px 0px 20px;
  .sub-heading-text {
    font-family: Rubik;
    font-size: 18px;
    line-height: 24px;
    color: #005C87;
    text-align: center;
  }
  .MuiSvgIcon-root {
    width: 28px !important;
    height: 42px !important;
  }

  .middle-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    font-size: 18px;
    color: #005c87;
    margin-bottom: 10px;
    font-weight: bold;
  }

  #event-upload-file {
    display: none;
  }

  .custom-upload-button {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    height: 48px;
    background-color: #FFFFFF;
    color: #007AB1;
    border: 1px solid #007AB1;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 16px;
    line-height: 20px;
    font-family: rubik;
    justify-content: center;
  }

  // .custom-upload-button:hover {
  //   background-color: #003f5c;
  // }

  .custom-upload-button svg {
    width: 24px;
    height: 24px;
    margin-left: 10px;
  }

  .confirm-details-container{
    margin-top: 25px;
    margin-bottom: 15px;
  }

`

const ButtonContainer = styled.div`
  width: 100%;
  justify-conatent: center;
  margin: auto;
`;

const ConfirmButton = styled.div`
width: ${({width}) => width ? width : '378px'};
height: 50px;
background: ${({background}) => background ? background :'linear-gradient(90deg, rgb(0, 111, 188) -1.11%, rgb(127, 67, 255) 97.46%)'};
margin: auto;
display: flex;
justify-content: center;
align-items: center;
border-radius: 6px;
cursor: pointer;
margin: ${({margin}) => margin ? margin :'25px auto 5px auto'};
font-family: 'Rubik-Medium';
font-size: 18px;
line-height: 15.3px;
color: #FFFFFF;
`;

const CalendarWrapper = styled.div`
  padding: ${({padding}) => padding ? padding : '0 25px'};
`

const CalendarImg = styled.div`
  text-align: center;
  >img{
    width: 179.46px;
    height: 107.08px;
  }
`
const CalendarHead = styled.div`
  margin: 20px 0;
  font-family: 'Rubik-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  text-align: center;
  color: #0D4270;
`
const CalendarPicker = styled.div``

const CalendarContainer = styled.div`
width: 100%;
background-color: white;
border-radius: 3px;
@media(min-width: 1550px) {
  width: 100%;
}
@media(max-width: 1450px) and (min-width: 1300px){
  width: 100%;
}
@media(max-width: 1250px) {
  width: 100%;
}
  .react-calendar
  {
    width: 100%;
    height: 100%;
    justify-content: flex-start;

    .react-calendar__navigation
    {
      display: flex;
      padding: 10px 20px;
      height: 15%;
      align-items: center;
      justify-content: space-between;
      button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button
      {
        border: none;
        background-color: white;
        display: none;
      }
      button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button
      {
        border: none;
        background-color: white;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #FD7175;
        color: white;
        font-size: 25px;

        &:focus {
          outline: none;
        }
      }
      button.react-calendar__navigation__arrow.react-calendar__navigation__next-button
      {
        border: none;
        background-color: white;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #FD7175;
        color: white;
        font-size: 25px;

        &:focus {
          outline: none;
        }
      }
      button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button
      {

        border: none;
        background-color: white;
        display: none;
      }
      .react-calendar__navigation__label
      {
        border: none;
        background-color: white;
        background-color: rgba(253, 113, 117, 0.11);
        border-radius: 24px;
        max-width: 200px;
        min-height: 30px;
        pointer-events: none;
        &:focus {
          outline: none;
        }
        span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from
        {
          font-size: 18px;
          font-family: 'Rubik-Medium';
          background: -webkit-linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
    .react-calendar__viewContainer
    {
      padding: 15px;
      width: 100%;
      height: 85%;

      @media(max-width: 1250px) {
        padding: 0px;
      }

      .react-calendar__month-view
      {
        width: 100%;
        height: 100%;
        background-color: #FFF;

        .react-calendar__month-view__weekdays
        {
          .react-calendar__tile.react-calendar__tile--active.react-calendar__month-view__weekdays__weekday
          {
            border-bottom: 4px solid #000;
          }
          .react-calendar__month-view__weekdays__weekday
          {
            padding-bottom: 10px;
            ${'' /* border-bottom: 4px solid #AFAFAF; */}
            text-align: center;
            abbr[title]
            {
              border-bottom: none;
              text-decoration: none;
              color: #AFAFAF;
            }
          }
        }
        .react-calendar__month-view__days
        {
          margin-top: 8px;
          justify-content: space-evenly;
          background-color: #FFF;
          height: 100%;
          width: 100%;

          button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day, button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day
          {
            background: #FD7175;
            flex-basis: unset !important;
            max-width: unset !important;
            overflow: unset !important;
            border: none;
            border-radius: 3px;
            width: 54px;
            height: 54px;
            margin: 3px 0;
            &:focus {
              outline: none;
            }

            > abbr
            {
              font-size: 13px;
              line-height: 19px;
              color: #FFF;
              text-align: center;
              font-family: Rubik;
              font-size: 18px;
            }

            @media(min-width: 1550px) {
              margin: 3px 0;
            }
            @media(max-width: 1450px) and (min-width: 1300px){
              margin: 5px 0;
            }
            @media(max-width: 1250px) {
              min-height: 33px;
              width: 33px;
              margin: 14px;
            }
            @media(max-width: 1223px) {
              margin: 13px;
            }
            @media(max-width: 1195px) {
              margin: 12px;
            }
            @media(max-width: 1167px) {
              margin: 11px;
            }
            @media(max-width: 1059px) {
              margin: 10px;
            }
            @media(max-width: 1046px) {
              margin: 9px;
            }
            @media(max-width: 1003px) {
              margin: 8px;
            }
            @media(max-width: 975px) {
              margin: 7px;
            }
            @media(max-width: 950px) {
              margin: 15px;
            }
            @media(max-width: 931px) {
              margin: 14px;
            }
            @media(max-width: 903px) {
              margin: 13px;
            }
            @media(max-width: 875px) {
              margin: 12px;
            }
            @media(max-width: 847px) {
              margin: 11px;
            }
            @media(max-width: 819px) {
              margin: 10px;
            }
            @media(max-width: 791px) {
              margin: 9px;
            }
            @media(max-width: 763px) {
              margin: 8px;
            }
            @media(max-width: 735px) {
              margin: 7px;
            }
            @media(max-width: 707px) {
              margin: 6px;
            }
            @media(max-width: 688px) {
              margin: 28px;
            }
            @media(max-width: 642px) {
              margin: 27px;
            }
            @media(max-width: 628px) {
              margin: 25px;
            }
            @media(max-width: 600px) {
              margin: 24px;
            }
            @media(max-width: 586px) {
              margin: 23px;
            }
            @media(max-width: 572px) {
              margin: 22px;
            }
            @media(max-width: 559px) {
              margin: 21px;
            }
            @media(max-width: 544px) {
              margin: 20px;
            }
            @media(max-width: 530px) {
              margin: 19px;
            }
            @media(max-width: 516px) {
              margin: 18px;
            }
            @media(max-width: 502px) {
              margin: 17px;
            }
            @media(max-width: 488px) {
              margin: 16px;
            }
            @media(max-width: 474px) {
              margin: 15px;
            }
            @media(max-width: 460px) {
              margin: 14px;
            }
            @media(max-width: 446px) {
              margin: 13px;
            }
            @media(max-width: 432px) {
              margin: 12px;
            }
            @media(max-width: 418px) {
              margin: 11px;
            }
            @media(max-width: 404px) {
              margin: 10px;
            }
            @media(max-width: 390px) {
              margin: 9px;
            }
            @media(max-width: 376px) {
              margin: 8px;
            }
            @media(max-width: 362px) {
              margin: 7px;
            }
            @media(max-width: 348px) {
              margin: 6px;
            }
            @media(max-width: 334px) {
              margin: 5px;
            }
            @media(max-width: 320px) {
              margin: 4px;
            }
          }
          ${({bgColor}) => bgColor && css`
            button.react-calendar__tile.react-calendar__tile--active {
              background: linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%) !important;
              color: white !important;
              border: none !important;

              > abbr
              {
                font-size: 13px !important;
                line-height: 19px;
                color: #FFF !important;
                text-align: center;
                font-family: Rubik;
              }

            }
          `}

          button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend
          {
            background-color: white;
            flex-basis: unset !important;
            max-width: unset !important;
            overflow: unset !important;
            width: 54px;
            height: 54px;
            border: none;
            background: #F8F8F8;
            border-radius: 3px;
            margin: 3px 0;
            >abbr{
              font-family: 'Rubik';
              font-style: normal;
              font-weight: normal;
              font-size: 18px;
              line-height: 16px;
              text-align: center;
              color: #3A3A3A;
            }
          }

          button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth
          {
            margin: 0;
            background: none !important;
            border: none;

            >abbr{
              display: none;
            }

            &:focus {
              outline: none;
            }

            @media(min-width: 1550px) {
              margin: 3px 0;
            }
            @media(max-width: 1450px) and (min-width: 1300px){
              margin: 5px 0;
            }
            @media(max-width: 1250px) {
              min-height: 33px;
              width: 33px;
              margin: 14px;
            }
            @media(max-width: 1223px) {
              margin: 13px;
            }
            @media(max-width: 1195px) {
              margin: 12px;
            }
            @media(max-width: 1167px) {
              margin: 11px;
            }
            @media(max-width: 1059px) {
              margin: 10px;
            }
            @media(max-width: 1046px) {
              margin: 9px;
            }
            @media(max-width: 1003px) {
              margin: 8px;
            }
            @media(max-width: 975px) {
              margin: 7px;
            }
            @media(max-width: 950px) {
              margin: 15px;
            }
            @media(max-width: 931px) {
              margin: 14px;
            }
            @media(max-width: 903px) {
              margin: 13px;
            }
            @media(max-width: 875px) {
              margin: 12px;
            }
            @media(max-width: 847px) {
              margin: 11px;
            }
            @media(max-width: 819px) {
              margin: 10px;
            }
            @media(max-width: 791px) {
              margin: 9px;
            }
            @media(max-width: 763px) {
              margin: 8px;
            }
            @media(max-width: 735px) {
              margin: 7px;
            }
            @media(max-width: 707px) {
              margin: 6px;
            }
            @media(max-width: 688px) {
              margin: 28px;
            }
            @media(max-width: 642px) {
              margin: 27px;
            }
            @media(max-width: 628px) {
              margin: 25px;
            }
            @media(max-width: 600px) {
              margin: 24px;
            }
            @media(max-width: 586px) {
              margin: 23px;
            }
            @media(max-width: 572px) {
              margin: 22px;
            }
            @media(max-width: 559px) {
              margin: 21px;
            }
            @media(max-width: 544px) {
              margin: 20px;
            }
            @media(max-width: 530px) {
              margin: 19px;
            }
            @media(max-width: 516px) {
              margin: 18px;
            }
            @media(max-width: 502px) {
              margin: 17px;
            }
            @media(max-width: 488px) {
              margin: 16px;
            }
            @media(max-width: 474px) {
              margin: 15px;
            }
            @media(max-width: 460px) {
              margin: 14px;
            }
            @media(max-width: 446px) {
              margin: 13px;
            }
            @media(max-width: 432px) {
              margin: 12px;
            }
            @media(max-width: 418px) {
              margin: 11px;
            }
            @media(max-width: 404px) {
              margin: 10px;
            }
            @media(max-width: 390px) {
              margin: 9px;
            }
            @media(max-width: 376px) {
              margin: 8px;
            }
            @media(max-width: 362px) {
              margin: 7px;
            }
            @media(max-width: 348px) {
              margin: 6px;
            }
            @media(max-width: 334px) {
              margin: 5px;
            }
            @media(max-width: 320px) {
              margin: 4px;
            }
          }
          
          button.react-calendar__tile.react-calendar__month-view__days__day
          {
            background-color: white;
            flex-basis: unset !important;
            max-width: unset !important;
            overflow: unset !important;
            width: 54px;
            height: 54px;
            border: none;
            background: #F8F8F8;
            border-radius: 3px;
            margin: 3px 0;
            >abbr{
              font-family: 'Rubik';
              font-style: normal;
              font-weight: normal;
              font-size: 18px;
              line-height: 16px;
              text-align: center;
              color: #3A3A3A;
            }

            &:focus {
              outline: none;
            }

            @media(min-width: 1550px) {
              margin: 3px 0;
            }
            @media(max-width: 1450px) and (min-width: 1300px){
              margin: 5px 0;
            }
            @media(max-width: 1250px) {
              min-height: 33px;
              width: 33px;
              margin: 14px;
            }
            @media(max-width: 1223px) {
              margin: 13px;
            }
            @media(max-width: 1195px) {
              margin: 12px;
            }
            @media(max-width: 1167px) {
              margin: 11px;
            }
            @media(max-width: 1059px) {
              margin: 10px;
            }
            @media(max-width: 1046px) {
              margin: 9px;
            }
            @media(max-width: 1003px) {
              margin: 8px;
            }
            @media(max-width: 975px) {
              margin: 7px;
            }
            @media(max-width: 950px) {
              margin: 15px;
            }
            @media(max-width: 931px) {
              margin: 14px;
            }
            @media(max-width: 903px) {
              margin: 13px;
            }
            @media(max-width: 875px) {
              margin: 12px;
            }
            @media(max-width: 847px) {
              margin: 11px;
            }
            @media(max-width: 819px) {
              margin: 10px;
            }
            @media(max-width: 791px) {
              margin: 9px;
            }
            @media(max-width: 763px) {
              margin: 8px;
            }
            @media(max-width: 735px) {
              margin: 7px;
            }
            @media(max-width: 707px) {
              margin: 6px;
            }
            @media(max-width: 688px) {
              margin: 28px;
            }
            @media(max-width: 642px) {
              margin: 27px;
            }
            @media(max-width: 628px) {
              margin: 25px;
            }
            @media(max-width: 600px) {
              margin: 24px;
            }
            @media(max-width: 586px) {
              margin: 23px;
            }
            @media(max-width: 572px) {
              margin: 22px;
            }
            @media(max-width: 559px) {
              margin: 21px;
            }
            @media(max-width: 544px) {
              margin: 20px;
            }
            @media(max-width: 530px) {
              margin: 19px;
            }
            @media(max-width: 516px) {
              margin: 18px;
            }
            @media(max-width: 502px) {
              margin: 17px;
            }
            @media(max-width: 488px) {
              margin: 16px;
            }
            @media(max-width: 474px) {
              margin: 15px;
            }
            @media(max-width: 460px) {
              margin: 14px;
            }
            @media(max-width: 446px) {
              margin: 13px;
            }
            @media(max-width: 432px) {
              margin: 12px;
            }
            @media(max-width: 418px) {
              margin: 11px;
            }
            @media(max-width: 404px) {
              margin: 10px;
            }
            @media(max-width: 390px) {
              margin: 9px;
            }
            @media(max-width: 376px) {
              margin: 8px;
            }
            @media(max-width: 362px) {
              margin: 7px;
            }
            @media(max-width: 348px) {
              margin: 6px;
            }
            @media(max-width: 334px) {
              margin: 5px;
            }
            @media(max-width: 320px) {
              margin: 4px;
            }

          }
        }
      }
      .react-calendar__year-view
      {
        width: 100%;
        height: 100%;

        .react-calendar__year-view__months
        {
          height: 100%;
          width: 100%;
          justify-content: space-evenly;

          button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__year-view__months__month
          {
            border: none;
            background-color: #FFF;
            font-size: 16px;
            font-family: 'Rubik-Medium';
            background: -webkit-linear-gradient(90deg,#006FBC -1.11%,#7F43FF 97.46%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            &:focus {
              outline: none;
            }
          }

          button.react-calendar__tile.react-calendar__year-view__months__month {
            border: none;
            background-color: #FFF;
            color: #000000;
            font-size: 16px;
            font-family: 'Rubik-Regular';

            &:focus {
              outline: none;
            }
          }
        }
      }
      .react-calendar__decade-view
      {
        width: 100%;
        height: 100%;

        .react-calendar__decade-view__years
        {
          width: 100%;
          height: 100%;

          button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__decade-view__years__year
          {
            border: none;
            background-color: #FFF;
            font-size: 16px;
            font-family: 'Rubik-Medium';
            background: -webkit-linear-gradient(90deg,#006FBC -1.11%,#7F43FF 97.46%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            &:focus {
              outline: none;
            }
          }

          button.react-calendar__tile.react-calendar__decade-view__years__year
          {
            border: none;
            background-color: #FFF;
            color: #000000;
            font-size: 16px;
            font-family: 'Rubik-Regular';

            &:focus {
              outline: none;
            }
          }
        }
      }
      .react-calendar__century-view
      {
        height: 100%;
        width: 100%;

        .react-calendar__century-view__decades
        {
          height: 100%;
          width: 100%;

          button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__century-view__decades__decade
          {
            border: none;
            background-color: #FFF;
            font-size: 16px;
            font-family: 'Rubik-Medium';
            background: -webkit-linear-gradient(90deg,#006FBC -1.11%,#7F43FF 97.46%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            &:focus {
              outline: none;
            }
          }

          button.react-calendar__tile.react-calendar__century-view__decades__decade
          {
            border: none;
            background-color: #FFF;
            color: #000000;
            font-size: 16px;
            font-family: 'Rubik-Regular';

            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }
  .react-calendar{
  .react-calendar__navigation {
    button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button{
    &:disabled {
      opacity: 0.4
    }
    }
   }
  }
  .react-calendar{
    .react-calendar__navigation {
      button.react-calendar__navigation__arrow.react-calendar__navigation__next-button{
      &:disabled {
        opacity: 0.4
      }
      }
     }
    }

`;

const TimerButton = styled.div`
  padding: 5px 30px;
  margin-top: 10px;
  cursor: pointer;
  position: relative;
  z-index:1000;
  >div{
    align-items: center;
    display: flex;
    justify-content: center;
    background: #FD7175;
    border-radius: 6px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    height: 50px;
  }
`

const CalendarHeader = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  background: linear-gradient(180deg,#52A8ED 0%,#8AD3F7 100%);
  border-radius: 6px 6px 0 0;
  > div: nth-child(1) {
    width:65%;

    font-family: Rubik-Medium;
    font-size: 18px;
    line-height: 24px;
    color:#FFF;
    margin: auto;
    padding-left: 25px;
  }
  > div: nth-child(2) {
    width: 35%;
    display: flex;
    justify-content: flex-end;
    >div{
      width: 24px;
      height: 24px;
      margin: auto;
      margin-right: 15px;
      >img{
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
  }

`

const ConfirmVisitText = styled.div`
  width: 100%;
  font-family: Rubik-Medium;
  font-size: 18px;
  line-height: 24px;
  color:#0D4270;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 30px 0px 40px 0px;
`

export { ConfirmDetailsContainer, RecommendedAmigosSection, RecommendedAmigosList, ConnectButton, TaskToCompleteSidebar, Header, TaskContainer, PointeContainer, Content, ConnectFitnessDeviceContainer, RightContainer, Arrow, SubContainer, ImageContainer, DataContainer, StyledModal, InitiativesContainer, ButtonContainer, ConfirmButton, CalendarWrapper,CalendarImg, CalendarHead, CalendarPicker, CalendarContainer, TimerButton, CalendarHeader, ConfirmVisitText };
