/* eslint-disable no-console,no-undef, camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Image from '../../Image';
import { RightMark } from '../../SvgComponentsV2';
import { TaskToCompleteSidebar, ConnectFitnessDeviceContainer, RecommendedAmigosList, RecommendedAmigosSection } from './Styles';

class RecommendedAmigos extends Component {
  constructor() {
    super();
    this.state = {
    };
  }


  componentDidMount() {
  }

  render() {
    const { friendRequests } = this.props;
    return (
      <RecommendedAmigosSection >
        <span>RECOMMENDED BUDDY</span>
        <TaskToCompleteSidebar>
          <ConnectFitnessDeviceContainer>
            {
              friendRequests.length > 0 ? friendRequests.map((friends, index) => (
                <RecommendedAmigosList key={index}>
                  <Image
                    image={friends.profile_image}
                    alt={friends.fullname}
                  />
                  <span>{friends.fullname}</span>
                  <span className="rightArrow" onClick={this.props.showRequestModal}>
                    <RightMark width="20px" height="20px" color="#67676A" />
                  </span>
                </RecommendedAmigosList>
              ))
                :
                <RecommendedAmigosList empty><span>{"No recommended buddy's to show"}</span></RecommendedAmigosList>
            }
          </ConnectFitnessDeviceContainer>
        </TaskToCompleteSidebar>
      </RecommendedAmigosSection>
    );
  }
}

RecommendedAmigos.propTypes = {
  friendRequests: PropTypes.array,
  showRequestModal: PropTypes.func
};

export default connect(null, null)(RecommendedAmigos);
